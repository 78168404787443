@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	/* .visual
	----------------------------------------------------------- */
	.visual {
		.slick-slide {
			margin: 0;
		}
	}
	

	/* .products
	----------------------------------------------------------- */
	.products {
		&__item {
			display: flex;
			flex-direction: column;
			font-size: $xxs;
			
			&__visual {
				flex: 0 0 auto;
				position: relative;
				display: block;
				margin: 0 0 10px;
				
				&__text {
					left: 15px;
					color: $textColor;
					font-family: Arial, Helvetica, "sans-serif";
					font-size: $m;
					font-weight: bold;
					line-height: 1;
					@include centering-elements(false, true);
				}
			}
			
			&__text {
				flex: 1 0 auto;
				margin-bottom: 10px;
			}
			
			&__link {
				flex: 0 0 auto;
				
				@media screen and (max-width: 360px) { font-size: 1.1rem; }
				@media screen and (max-width: 320px) { font-size: $xxxs; }
			}
		}
	}
	
	
}