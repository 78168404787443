@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	width: 100%;
	
	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1040px;
		height: 100px;
		margin: 0 auto;
		padding: 20px;
		box-sizing: border-box;
	}
	
	
	&__logo {
		&--ciel {
			flex: 0 0 51px;
			margin: 0 15px 0 0;
		}

		&--rolland {
			flex: 0 0 175px;
			margin: 0 30px 0 0;
		}
	}
	
	&__content {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
	}

	&__search {
		flex: 1 1 auto;
		max-width: 420px;
		margin: 0 20px 0 0;
		border: 1px solid $gray;
		border-radius: 5px;

		&__form {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		&__field[type="text"] {
			border: none;
			flex: 0 1 auto;
			height: 35px;
			width: 100%;
			max-width: 320px;
			border-radius: 5px;
		}

		&__button {
			flex: 0 0 40px;
			cursor: pointer;
		}
	}

	&__nav {
		flex: 0 0 auto;
		display: flex;
		margin-left: auto;
		
		&__item {
			position: relative;
			color: $textColor;
			text-align: center;
			margin: 0 10px;
			padding: 0 5px; // 検索ボックス表示する際は削除
			@include transition;
			
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				text-decoration: none;
				@include opacity;
			}

			&__icon {
				font-size: 3.5rem;
				display: block;
				line-height: 0.8;
			}
			
			&__img {
				width: 75px;
				display: block;
				margin: 0 auto 3px;
				@media print, screen and (max-width: 880px){
					width: 60px;
				}
			}

			&__text {
				font-size: 1.0rem;
				white-space: nowrap;
			}

		}
	}
	
	
}


/* ============================================================ */
/* gnav */
/* ============================================================ */
.gnav {
	max-width: 1040px;
	margin: 0 auto 60px;
	padding: 0 20px;
	
	&__inner {
		display: flex;
		border: 1px solid $borderColor;
		@include radius(4);
	}
	
	&__item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% / 4);
		height: 70px;
		color: $textColor;
		font-size: $m;
		font-weight: bold;
		
		&::before {
			position: absolute;
			top: 5px;
			bottom: 5px;
			left: 0;
			content: '';
			border-left: 1px solid $borderColor;
		}
		
		&::after {
			position: absolute;
			bottom: -1px;
			left: 0;
			content: '';
			width: 100%;
			height: 2px;
			background: #333;
			transform: scale(0, 1);
			transform-origin: center top;
			@include transition;
		}
		
		&:first-child {
			&::before {
				display: none;
			}
		}
		
		&:hover {
			text-decoration: none;

			&::after {
				transform: scale(1, 1);
			}
		}
	}
}
