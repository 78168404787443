@charset "utf-8";
/* ============================================================ */
/* media */
/* ============================================================ */
.media {
	
	.column3 {
		margin-left: -1.5%;
		margin-right: -1.5%;

		&> * {
			margin-left: 1.5%;
			margin-right: 1.5%;
			margin-bottom: 60px;
			width: 30.3%;
			
			& > img {
				width: 80%;
				margin: 0 auto;
				display: block;
			}			
		}
	}


}


