@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	line-height: 1.5;
	
	.fnav {
		background: #414141;
		font-size: $xxxs;
		
		a {
			color: $white;
		}
		&__level1,
		&__level2 {
			border-top: 1px solid rgba($white, 0.1);
			color: $white;
			font-weight: bold;
			
			& > *:not([class^="icon-"]) {
				flex: 1 1 auto;
			}
			.icon-angle-down {
				@include transition;
			}
			&.active {
				.icon-angle-down {
					transform: rotate(180deg);
				}
			}
		}
		
		&__level1 {
			display: flex;
			align-items: center;
			padding: 10px 15px;
			background: #333;
			font-size: $s;
		}
		&__level2 {
			display: flex;
			padding: 10px 15px;
			font-size: $xxs;
		}

		.fnav__accordion + * {
			display: none;
		}
		.list {
			padding: 15px;
			
			& > * {
				margin-bottom: 10px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
			&--circle {
				& > * {
					padding-left: 10px;

					&::before {
						top: 0.8em;
						left: 3px;
						width: 2px;
						height: 2px;
					}
				}
			}
			
		}
		
		.fnav__level2 + .list {
			padding-top: 0;
		}
	}
	
	.copyright {
		padding: 15px 0;
		background: #201f21;
		&__link {
			width: 100px;
			margin: 0 auto 10px;
			text-align: center;
			display: block;
		}
		&__text {
			color: #7b7b7b;
			font-size: $xxxs;
			text-align: center;
		}
	}
}