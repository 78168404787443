@charset "utf-8";
/* ============================================================ */
/* media */
/* ============================================================ */
.media {
	
	.column3 {
		& > * {
			margin-bottom: 60px;

			& > img {
				width: 80%;
				margin: 0 auto;
				display: block;
			}
		}
	}
	
}


