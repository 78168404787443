@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	margin: 0 auto 80px;
	
	&__inner {
		max-width: 1040px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&--column2 {
		.main {
			flex: 0 1 auto;
			width: 730px;
			margin: 0 30px 0 0;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 73vw; }
		}
		.side {
			flex: 0 0 auto;
			width: 240px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 24vw; }
		}
	}
	&--column3 {
		.main {
			order: 1;
			flex: 0 1 auto;
			width: 540px;
			margin: 0 30px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 54vw; }
		}
		.left-contents {
			order: 0;
			flex: 0 0 auto;
			width: 200px;
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 20vw; }
		}
		.right-contents {
			order: 2;
			flex: 0 0 auto;
			width: 200px;
			
			@media screen and (min-width:  767.5px) and (max-width:1024px) { width: 20vw; }
		}
	}
	
	
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.lead {
	font-size: $l;
	text-align: center;
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 15px;
	font-family: Arial, Helvetica, "sans-serif";
	font-weight: bold;
	font-size: 6.0rem;
	text-align: center;
	line-height: 1;
}

.ttl02 {
	margin: 0 0 30px;
	font-size: $s;
	text-align: center;
	line-height: 1.5;
	
	&::before {
		display: block;
		content: attr(data-text);
		font-size: 3.0rem;
		text-transform: uppercase;
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 60px;
	padding: 0 0 20px;
	font-size: 3.0rem;
	text-align: center;
	line-height: 1.4;
	
	&__product-name {
		font-size: $xxxl;
		text-align: center;
		line-height: 1;

		&--daily-act { color: $daily_act; }
		&--moisturizing { color: $moisturizing; }
		&--colorup { color: $colorup; }
		&--xvolume { color: $xvolume; }
		&--silk-and-glow { color: $silk-and-glow; }
		&--be-curly { color: $becurly; }
		&--smooth { color: $smooth; }
		&--sunway { color: $sunway; }
		&--flowerfall { color: $flowerfall; }
		
		&--rebuilding { color: $rebuilding; }
		&--no-dandruff { color: $no-dandruff; }
		&--rebalancing { color: $rebalancing; }
		&--soothing { color: $soothing; }
		&--hairloss { color: $hairloss; }
		
		&--styling { color: $styling; }
		&--richgreen { color: $richgreen; }
		
		&--life { color: $life; }
	}
	
	&::after {
		bottom: 0;
		content: '';
		width: 45px;
		border-bottom: 3px solid #ddd;
		@include centering-elements(true, false);
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 20px;
	padding: 10px 15px;
	border: 1px solid #ddd;
	font-size: $l;
	font-weight: bold;
	line-height: 1.4;
	@include radius(4);
	
	&::before {
		left: -1px;
		content: '';
		height: 20px;
		border-left: 3px solid $textColor;
		@include centering-elements(false, true);
	}
}

.ttl05 {
	position: relative;
	margin: 0 0 15px;
	padding: 0 0 0 10px;
	padding: 0 0 10px;
	border-left: 3px solid $textColor;
	border: none;
	border-bottom: 1px solid #ddd;
	font-size: $m;
	font-weight: bold;
	line-height: 1.4;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: -1px;
		content: '';
		width: 75px;
		border-bottom: 2px solid $textColor;
	}
}

.ttl06 {
	margin: 0 0 15px;
	font-size: $m;
	font-weight: bold;
	line-height: 1.4;
}

.ttl07 {
	position: relative;
	margin: 0 0 30px;
	padding: 0 0 10px;
	font-size: $xxl;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	
	&::after {
		bottom: 0;
		content: '';
		width: 30px;
		border-bottom: 3px solid #d2d2d2;
		@include centering-elements(true, false);
	}
}



/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -1%;
	 margin-right: -1%;

	 &> * {
		 margin-left: 1%;
		 margin-right: 1%;
		 width: 48%;
	 }
 }

.column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31.3%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--white {
		& > * {
			&::before {
				background: $white;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--gray {
		background: #f7f7f7;
	}

	&--border-gray {
		border: 1px solid $borderColor;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 10px 15px;
		background: $white;
		border: 1px solid $borderColor;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-black {
		background: #444;
		color: $white;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		font-size: $xs;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
	background: none;
}

.btn,
input[type="button"].btn,
input[type="submit"].btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 50px;
	margin: 5px;
	padding: 0 30px;
	background: $white;
	font-size: $m;
	text-align: center;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;
	@include radius(3);

	.icon-chevron-right,
	.icon-chevron-down {
		right: 15px;
		font-size: $xxxs;
		@include centering-elements(false, true);
	}
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--red {
		background: $baseColor;
		box-shadow: 0 2px 0 rgba(#000, 0.15);
		color: $white;
	}
	&--black {
		background: $textColor;
		box-shadow: 0 2px 0 rgba(#000, 0.15);
		color: $white;
	}
	&--lg {
		min-width: 300px;
		height: 60px;
		font-size: $m;
	}
	&--sm {
		min-width: inherit;
		height: 30px;
		margin: 2px;
		padding: 0 10px;
		font-size: $xxs;
	}
	&--daily-act { @include btn-lineup($daily-act);
		&:hover { @include btn-lineup-hover($daily-act); }
	}
	&--moisturizing { @include btn-lineup($moisturizing);
		&:hover { @include btn-lineup-hover($moisturizing); }
	}
	&--colorup { @include btn-lineup($colorup);
		&:hover { @include btn-lineup-hover($colorup); }
	}
	&--xvolume { @include btn-lineup($xvolume);
		&:hover { @include btn-lineup-hover($xvolume); }
	}
	&--silk-and-glow { @include btn-lineup($silk-and-glow);
		&:hover { @include btn-lineup-hover($silk-and-glow); }
	}
	&--becurly { @include btn-lineup($becurly);
		&:hover { @include btn-lineup-hover($becurly); }
	}
	&--smooth { @include btn-lineup($smooth);
		&:hover { @include btn-lineup-hover($smooth); }
	}
	&--sunway { @include btn-lineup($sunway);
		&:hover { @include btn-lineup-hover($sunway); }
	}
	&--flowerfall { @include btn-lineup($flowerfall);
		&:hover { @include btn-lineup-hover($flowerfall); }
	}
	&--rebuilding { @include btn-lineup($rebuilding);
		&:hover { @include btn-lineup-hover($rebuilding); }
	}
	&--no-dandruff { @include btn-lineup($no-dandruff);
		&:hover { @include btn-lineup-hover($no-dandruff); }
	}
	&--rebalancing { @include btn-lineup($rebalancing);
		&:hover { @include btn-lineup-hover($rebalancing); }
	}
	&--soothing { @include btn-lineup($soothing);
		&:hover { @include btn-lineup-hover($soothing); }
	}
	&--hairloss { @include btn-lineup($hairloss);
		&:hover { @include btn-lineup-hover($hairloss); }
	}
	&--styling { @include btn-lineup($styling);
		&:hover { @include btn-lineup-hover($styling); }
	}
	&--richgreen { @include btn-lineup($richgreen);
		&:hover { @include btn-lineup-hover($richgreen); }
	}
	&--life { @include btn-lineup($life);
		&:hover { @include btn-lineup-hover($life); }
	}
	
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			@include radius(3);
			
			&.current,
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 80px;
	height: 25px;
	background: $baseColor;
	color: #fff;
	font-size: $xxs;
	text-align: center;
	line-height: 25px;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 40px;
	border-top: 1px solid $borderColor;
	
	&__item {
		display: flex;
		align-items: center;
		padding: 15px 0;
		border-bottom: 1px solid $borderColor;
		
		&__date,
		&__label {
			flex: 0 0 auto;
			margin: 0 20px 0 0;
		}
		&__link {
			flex: 1 1 auto;

			.icon-filter_none {
				color: $baseColor;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
	}
	&__block {
		padding: 0 0 50px;
		border-bottom: 1px solid $borderColor;
	}
}


/* --------------------------------------------------- */
/* products-list */
/* --------------------------------------------------- */
.products-list {
	&__item {
		display: flex;
		flex-direction: column;
		line-height: 1.5;
		
		& > * {
			flex: 0 0 auto;
		}
		
		&__img {
			&__link {
				display: block;
				img {
					transition: all 0.5s;
				}

				&:hover {
					img {
						transform: scale(1.08);
						opacity: 0.8;
					}
				}
			}
		}
		
		&__info {
			flex: 1 1 auto;
			&__ttl {
				font-size: $s;
			}
		}
	}
}


/* --------------------------------------------------- */
/* products-name */
/* --------------------------------------------------- */
.products-name {
	&__ttl {
		margin: 0 0 10px;
		font-size: 3.4rem;
		line-height: 1.2;
	}
	&__txt {
		line-height: 1.6;
	}
	&__component {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-left: -3px;
		margin-right: -3px;

		&__item {
			margin: 0 3px 5px;
			padding: 5px 10px;
			border-style: solid;
			border-width: 1px;
			font-size: 1.1rem;
			line-height: 1.4;
			@include radius(3);
		}
	}
	
	&--daily-act {
		.products-name__ttl {
			color: $daily-act;
		}
		.products-name__component__item {
			border-color: $daily-act;
			color: $daily-act;
		}
	}

	&--moisturizing {
		.products-name__ttl {
			color: $moisturizing;
		}
		.products-name__component__item {
			border-color: $moisturizing;
			color: $moisturizing;
		}
	}

	&--colorup {
		.products-name__ttl {
			color: $colorup;
		}
		.products-name__component__item {
			border-color: $colorup;
			color: $colorup;
		}
	}

	&--xvolume {
		.products-name__ttl {
			color: $xvolume;
		}
		.products-name__component__item {
			border-color: $xvolume;
			color: $xvolume;
		}
	}

	&--silk-and-glow {
		.products-name__ttl {
			color: $silk-and-glow;
		}
		.products-name__component__item {
			border-color: $silk-and-glow;
			color: $silk-and-glow;
		}
	}

	&--becurly {
		.products-name__ttl {
			color: $becurly;
		}
		.products-name__component__item {
			border-color: $becurly;
			color: $becurly;
		}
	}

	&--smooth {
		.products-name__ttl {
			color: $smooth;
		}
		.products-name__component__item {
			border: 1px solid $smooth;
			color: $smooth;
		}
	}

	&--sunway {
		.products-name__ttl {
			color: $sunway;
		}
		.products-name__component__item {
			border-color: $sunway;
			color: $sunway;
		}
	}

	&--flowerfall {
		.products-name__ttl {
			color: $flowerfall;
		}
		.products-name__component__item {
			border-color: $flowerfall;
			color: $flowerfall;
		}
	}

	&--rebuilding {
		.products-name__ttl {
			color: $rebuilding;
		}
		.products-name__component__item {
			border-color: $rebuilding;
			color: $rebuilding;
		}
	}

	&--no-dandruff {
		.products-name__ttl {
			color: $no-dandruff;
		}
		.products-name__component__item {
			border-color: $no-dandruff;
			color: $no-dandruff;
		}
	}
	&--rebalancing {
		.products-name__ttl {
			color: $rebalancing;
		}
		.products-name__component__item {
			border-color: $rebalancing;
			color: $rebalancing;
		}
	}

	&--soothing {
		.products-name__ttl {
			color: $soothing;
		}
		.products-name__component__item {
			border-color: $soothing;
			color: $soothing;
		}
	}

	&--hairloss {
		.products-name__ttl {
			color: $hairloss;
		}
		.products-name__component__item {
			border-color: $hairloss;
			color: $hairloss;
		}
	}

	&--styling {
		.products-name__ttl {
			color: $styling;
		}
		.products-name__component__item {
			border-color: $styling;
			color: $styling;
		}
	}

	&--richgreen {
		.products-name__ttl {
			color: $richgreen;
		}
		.products-name__component__item {
			border-color: $richgreen;
			color: $richgreen;
		}
	}

	&--life {
		.products-name__ttl {
			color: $life;
		}
		.products-name__component__item {
			border-color: $life;
			color: $life;
		}
	}
	
}


/* --------------------------------------------------- */
/* lineup */
/* --------------------------------------------------- */
.lineup {
	padding: 40px 0;
	background: #f7f7f7;
	
	&__inner {
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 20px;
	}
	.btn {
		height: 60px;
		margin-bottom: 10px;
		font-size: $l;
	}
}



/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__ttl {
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 120px;
			padding: 5px 0 0;
			background: $textColor;
			color: $white;
			font-size: $l;
			text-align: center;
			
			@media screen and (min-width:  767.5px) and (max-width:1024px) { height: 11.8vh; }

			&::before {
				top: 0;
				content: '';
				width: 50px;
				height: 5px;
				background: $baseColor;
				@include centering-elements(true, false);
			}
		}
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 20px 15px;
		border: 1px solid $borderColor;
		border-top: none;

		&__text {
			flex: 1 1 auto;
			font-size: $s;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 0 0 10px;
			font-size: $xxs;
		}
		&:hover {
			opacity: 1;
			background: #f0f0f0;
		}
	}
}
