@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	.inner {
		display: flex;
		align-items: center;
		height: 60px;
		padding: 0 15px;
		border-bottom: 1px solid $borderColor;
	}

	&__logo {
		margin: 0 20px 0 0;
		
		&--ciel {
			flex: 0 0 30px;
		}
		&--rolland {
			flex: 0 0 150px;
		}
	}
	
	&__menu {
		flex: 0 0 auto;
		position: relative;
		width: 24px;
		height: 18px;
		margin-left: auto;
		z-index: 10;
		cursor: pointer;

		&__item {
			display: inline-block;
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background: $textColor;
			@include transition;

			&:nth-of-type(1) { top: 0; }
			&:nth-of-type(2) { top: 8px; }
			&:nth-of-type(3) { bottom: 0; }
		}
		&.active {
			.header__menu__item {
				background: $white;
				&:nth-of-type(1) { transform: translateY(8px) rotate(-315deg); }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-8px) rotate(315deg); } 
			}
		}
	}
	
	&__content {
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 65px 15px 15px;
		background: rgba(#000, 0.85);
		opacity: 0;
		@include transition;
		
		.show & {
			z-index: 1;
			opacity: 1;
		}
	}
	
	&__search {
		margin: 0 0 30px;
		border: 1px solid $gray;
		border-radius: 5px;

		&__form {
			display: flex;
			justify-content: space-between;
			width: 100%;
			background: $white;
		}

		&__field[type="text"] {
			border: none;
			flex: 1 1 auto;
			height: 40px;
			border-radius: 5px;
		}

		&__button {
			flex: 0 0 40px;
			font-size: $xxl;
			cursor: pointer;
		}
	}
	
	&__nav {
		&__item {
			display: flex;
			align-items: center;
			padding: 15px 10px;
			border-bottom: 1px solid rgba($white, 0.2);
			color: $white;
			font-size: $m;

			&:first-child {
				border-top: 1px solid rgba($white, 0.2);
			}
			&__icon {
				margin: 0 10px 0 0;
				font-size: $xxxl;
				font-size: 3.8rem;
			}
			&__img {
				width: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 15px;
			}
			&__text {
				flex: 1 1 auto;
			}
		}
	}
	
	
}



/* ============================================================ */
/* gnav */
/* ============================================================ */
.gnav {
	margin: 20px 15px 30px;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		border: 1px solid $borderColor;
		@include radius(4);
	}
	
	&__item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		height: 40px;
		color: $textColor;
		font-size: $xs;
		font-weight: bold;
		
		&:nth-child(-n+2) {
			border-bottom: 1px solid $borderColor;
		}
		&:nth-child(2n+1) {
			border-right: 1px solid $borderColor;
		}
	}
}
