@charset "utf-8";

/* ============================================================ */
/* _rolland */
/* ============================================================ */
.rolland {
	#video {
		display: none;

		video {
			width: 100% !important;
		}
	}
	.fancybox-inner {
		overflow: hidden !important;
	}

}