@charset "utf-8";

/* ============================================================ */
/* _rolland */
/* ============================================================ */
.rolland {
	#video {
		display: none;
		
		video {
			max-width: 960px;
			width: 100%;
		}
	}
	.fancybox-inner {
		overflow: hidden !important;
	}
}