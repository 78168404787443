@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {

	.slider {
		max-width: 1260px;
		margin: 0 auto;

		.slick-slide {
			margin: 0 10px;
		}
		
		.slick-prev,
		.slick-next {
			width: 40px;
			height: 80px;
			z-index: 1;
			
			&::before {
				color: $white;
				font-family: 'icomoon';
				font-size: 8.0rem;
				line-height: 1;
			}
		}
		.slick-prev {
			left: 11.5%;
			
			&::before {
				content: "\f104";
			}
		}		
		.slick-next {
			right: 11.5%;
			
			&::before {
				content: "\f105";
			}
		}
	}
	
	
	/* .products
	----------------------------------------------------------- */
	.products {
		&__item {
			font-size: $m;
			
			&__visual {
				position: relative;
				display: block;
				margin: 0 0 10px;
				
				&__text {
					left: 35px;
					color: $textColor;
					font-family: Arial, Helvetica, "sans-serif";
					font-size: 3.8rem;
					font-weight: bold;
					line-height: 1;
					@include centering-elements(false, true);
				}
			}
		}
	}
	
	
	
}