@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	margin: 0 0 30px;
	
	&__inner {
		display: flex;
		flex-direction: column;
		margin: 0 15px;
	}
	&--column2 {
		.main {
			margin-bottom: 40px;
		}
		
	}
	&--column3 {
		.main {
			order: 0;
			margin-bottom: 40px;
		}
		.left-contents { order: 2; }
		.right-contents { order: 1; }
	}
	
}





/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.lead {
	font-size: 1.5rem;
	text-align: center;
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 15px;
	font-family: Arial, Helvetica, "sans-serif";
	font-weight: bold;
	font-size: 3.0rem;
	text-align: center;
	line-height: 1;
}

.ttl02 {
	margin: 0 0 20px;
	font-size: $xxs;
	text-align: center;
	line-height: 1.5;
	
	&::before {
		display: block;
		content: attr(data-text);
		font-size: 2.2rem;
		text-transform: uppercase;
	}
}

.ttl03 {
	position: relative;
	margin: 0 0 30px;
	padding: 0 0 15px;
	font-size: $xxl;
	text-align: center;
	line-height: 1.5;
	
	&__product-name {
		margin: 0 0 5px;
		font-size: $xl;
		text-align: center;
		
		&--daily-act { color: $daily_act; }
		&--moisturizing { color: $moisturizing; }
		&--colorup { color: $colorup; }
		&--xvolume { color: $xvolume; }
		&--silk-and-glow { color: $silk-and-glow; }
		&--be-curly { color: $becurly; }
		&--smooth { color: $smooth; }
		&--sunway { color: $sunway; }
		&--flowerfall { color: $flowerfall; }
		
		&--rebuilding { color: $rebuilding; }
		&--no-dandruff { color: $no-dandruff; }
		&--rebalancing { color: $rebalancing; }
		&--soothing { color: $soothing; }
		&--hairloss { color: $hairloss; }
		
		&--styling { color: $styling; }
		&--richgreen { color: $richgreen; }
		
		&--life { color: $life; }
	}
	
	&::after {
		bottom: 0;
		content: '';
		width: 45px;
		border-bottom: 2px solid #ddd;
		@include centering-elements(true, false);
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 15px;
	padding: 10px 15px;
	border: 1px solid #ddd;
	font-size: $m;
	font-weight: bold;
	line-height: 1.4;
	@include radius(4);
	
	&::before {
		left: -1px;
		content: '';
		height: 20px;
		border-left: 3px solid $textColor;
		@include centering-elements(false, true);
	}
}

.ttl05 {
	position: relative;
	margin: 0 0 15px;
	padding: 0 0 0 10px;
	padding: 0 0 10px;
	border-left: 3px solid $textColor;
	border: none;
	border-bottom: 1px solid #ddd;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 1.4;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: -1px;
		content: '';
		width: 75px;
		border-bottom: 2px solid $textColor;
	}
}

.ttl06 {
	margin: 0 0 15px;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 1.4;
}

.ttl07 {
	position: relative;
	margin: 0 0 25px;
	padding: 0 0 10px;
	font-size: $l;
	text-align: center;
	line-height: 1.5;
	
	&::after {
		bottom: 0;
		content: '';
		width: 30px;
		border-bottom: 2px solid #d2d2d2;
		@include centering-elements(true, false);
	}
}



/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--white {
		& > * {
			&::before {
				background: $white;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: #fff;

	&--gray {
		background: #f7f7f7;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid $borderColor;
	table-layout: fixed;
	font-size: $xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border: 1px solid #d5d5d5;
		text-align: left;
		vertical-align: middle;
	}
	.bg-black {
		background: #444;
		color: $white;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
	background: none;
}

button[class^="btn"] {
	display: -webkit-inline-box;
	display: -moz-inline-box;
}

.btn,
input[type="button"].btn,
input[type="submit"].btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 40px;
	margin: 5px 0;
	padding: 0 30px;
	background: $white;
	text-align: center;
	line-height: 1.4;
	@include radius(3);
	
	.icon-chevron-right,
	.icon-chevron-down {
		right: 10px;
		font-size: $xxxs;
		@include centering-elements(false, true);
	}
	
	&--red {
		background: $baseColor;
		box-shadow: 0 2px 0 rgba(#000, 0.15);
		color: $white;
	}
	&--black {
		background: $textColor;
		box-shadow: 0 2px 0 rgba(#000, 0.15);
		color: $white;
	}
	&--lg {
		width: 100%;
		height: 50px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		height: 30px;
		padding: 0 20px;
		font-size: 1.1rem;
	}
	&--daily-act { @include btn-lineup($daily-act); }
	&--moisturizing { @include btn-lineup($moisturizing); }
	&--colorup { @include btn-lineup($colorup); }
	&--xvolume { @include btn-lineup($xvolume); }
	&--silk-and-glow { @include btn-lineup($silk-and-glow); }
	&--becurly { @include btn-lineup($becurly); }
	&--smooth { @include btn-lineup($smooth); }
	&--sunway { @include btn-lineup($sunway); }
	&--flowerfall { @include btn-lineup($flowerfall); }
	&--rebuilding { @include btn-lineup($rebuilding); }
	&--no-dandruff { @include btn-lineup($no-dandruff); }
	&--rebalancing { @include btn-lineup($rebalancing); }
	&--soothing { @include btn-lineup($soothing); }
	&--hairloss { @include btn-lineup($hairloss); }
	&--styling { @include btn-lineup($styling); }
	&--richgreen { @include btn-lineup($richgreen); }
	&--life { @include btn-lineup($life); }
	
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			@include radius(3);
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: #fff;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #f3f3f3;
	border: 1px solid #d5d5d5;
	cursor: pointer;
	line-height: 1.6;
	@include radius(3);
	
	&__icon {
		right: 10px;
		color: $baseColor;
		font-size: $xxs;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 0 0;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 45px;
	padding: 2px 0;
	background: $baseColor;
	color: #fff;
	font-size: $xxxs;
	text-align: center;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 40px;
	border-top: 1px solid $borderColor;
	
	&__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 15px 0;
		border-bottom: 1px solid $borderColor;
		
		&__date {
			margin: 0 10px 0 0;
			font-size: $xxs;
		}
		&__link {
			width: 100%;
			margin: 5px 0 0;
			
			.icon-filter_none {
				color: $baseColor;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 20px;
	}
	&__block {
		padding: 0 0 30px;
		border-bottom: 1px solid $borderColor;
	}
}


/* --------------------------------------------------- */
/* products-list */
/* --------------------------------------------------- */
.products-list {
	&__item {
		display: flex;
		flex-direction: column;
		
		& > * {
			flex: 0 0 auto;
		}
		
		&__info {
			flex: 1 1 auto;
			&__ttl {
				font-size: 1.1rem;
			}
		}
	}
}


/* --------------------------------------------------- */
/* products-name */
/* --------------------------------------------------- */
.products-name {
	text-align: center;
	&__ttl {
		margin: 0 0 10px;
		font-size: 2.6rem;
		line-height: 1.2;
	}
	&__txt {
		line-height: 1.6;
	}
	&__component {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-left: -3px;
		margin-right: -3px;

		&__item {
			margin: 0 3px 5px;
			padding: 3px 5px;
			border-style: solid;
			border-width: 1px;
			font-size: $xxxs;
			line-height: 1.4;
			@include radius(3);
		}
	}
	
	&--daily-act {
		.products-name__ttl {
			color: $daily-act;
		}
		.products-name__component__item {
			border-color: $daily-act;
			color: $daily-act;
		}
	}

	&--moisturizing {
		.products-name__ttl {
			color: $moisturizing;
		}
		.products-name__component__item {
			border-color: $moisturizing;
			color: $moisturizing;
		}
	}

	&--colorup {
		.products-name__ttl {
			color: $colorup;
		}
		.products-name__component__item {
			border-color: $colorup;
			color: $colorup;
		}
	}

	&--xvolume {
		.products-name__ttl {
			color: $xvolume;
		}
		.products-name__component__item {
			border-color: $xvolume;
			color: $xvolume;
		}
	}

	&--silk-and-glow {
		.products-name__ttl {
			color: $silk-and-glow;
		}
		.products-name__component__item {
			border-color: $silk-and-glow;
			color: $silk-and-glow;
		}
	}

	&--becurly {
		.products-name__ttl {
			color: $becurly;
		}
		.products-name__component__item {
			border-color: $becurly;
			color: $becurly;
		}
	}

	&--smooth {
		.products-name__ttl {
			color: $smooth;
		}
		.products-name__component__item {
			border-color: $smooth;
			color: $smooth;
		}
	}

	&--sunway {
		.products-name__ttl {
			color: $sunway;
		}
		.products-name__component__item {
			border-color: $sunway;
			color: $sunway;
			font-size: 1.0rem;
		}
	}

	&--flowerfall {
		.products-name__ttl {
			color: $flowerfall;
		}
		.products-name__component__item {
			border-color: $flowerfall;
			color: $flowerfall;
		}
	}

	&--rebuilding {
		.products-name__ttl {
			color: $rebuilding;
		}
		.products-name__component__item {
			border-color: $rebuilding;
			color: $rebuilding;
		}
	}

	&--no-dandruff {
		.products-name__ttl {
			color: $no-dandruff;
		}
		.products-name__component__item {
			border-color: $no-dandruff;
			color: $no-dandruff;
		}
	}
	&--rebalancing {
		.products-name__ttl {
			color: $rebalancing;
		}
		.products-name__component__item {
			border-color: $rebalancing;
			color: $rebalancing;
		}
	}

	&--soothing {
		.products-name__ttl {
			color: $soothing;
		}
		.products-name__component__item {
			border-color: $soothing;
			color: $soothing;
		}
	}

	&--hairloss {
		.products-name__ttl {
			color: $hairloss;
		}
		.products-name__component__item {
			border-color: $hairloss;
			color: $hairloss;
		}
	}

	&--styling {
		.products-name__ttl {
			color: $styling;
		}
		.products-name__component__item {
			border-color: $styling;
			color: $styling;
		}
	}

	&--richgreen {
		.products-name__ttl {
			color: $richgreen;
		}
		.products-name__component__item {
			border-color: $richgreen;
			color: $richgreen;
		}
	}

	&--life {
		.products-name__ttl {
			color: $life;
		}
		.products-name__component__item {
			border-color: $life;
			color: $life;
		}
	}
	
}





/* --------------------------------------------------- */
/* lineup */
/* --------------------------------------------------- */
.lineup {
	padding: 25px 0;
	background: #f7f7f7;
	
	&__inner {
		margin: 0 15px;
	}
	.btn {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
		min-width: 0;
		height: 45px;
		padding: 0 10px;
	}
}



/* ============================================================ */
/* .side */
/* ============================================================ */

/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	margin: 0 0 20px;
	line-height: 1.4;

	&__ttl {
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 65px;
			background: $textColor;
			color: $white;
			font-size: $m;
			text-align: center;

			&::before {
				top: 0;
				content: '';
				width: 25px;
				height: 2px;
				background: $baseColor;
				@include centering-elements(true, false);
			}
		}
	}

	&__inner {
		display: flex;
		flex-wrap: wrap;
		border: 1px solid $borderColor;
		border-top: none;
	}
	&__item {
		display: flex;
		align-items: center;
		width: 50%;
		margin: 0 0 -1px;
		padding: 15px 10px;
		border-bottom: 1px solid $borderColor;

		&:nth-child(2n+1) {
			border-right: 1px solid $borderColor;
		}

		&__text {
			flex: 1 1 auto;
			font-size: $xxs;
		}
		.icon-angle-right {
			flex: 0 0 auto;
			margin: 0 0 0 10px;
			font-size: $xxxs;
		}
	}
}
