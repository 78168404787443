@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: #333;
	color: $white;
	line-height: 1.6;
	
	.fnav {
		max-width: 1040px;
		margin: 0 auto;
		padding: 60px 20px 40px;
		font-size: 1.1rem;
		
		&__row {
			display: flex;
			margin-left: -1%;
			margin-right: -1%;
		}
		&__col {
			width: 23%;
			margin: 0 1%;
		}
		&__col-3 {
			width: 73%;
			margin: 0 1%;
		}
		
		a {
			color: $white;
		}
		&__level1 {
			margin: 0 0 10px;
			font-size: $m;
			font-weight: bold;
		}
		&__level2 {
			margin: 0 0 5px;
			font-size: $xs;
			font-weight: bold;
		}
		
		.list--circle {
			& > * {
				padding-left: 10px;
				
				&::before {
					top: 0.8em;
					left: 3px;
					width: 2px;
					height: 2px;
				}
			}
		}
	}

	.copyright {
		padding: 20px 0;
		background: #201f21;
		text-align: center;
		&__link {
			display: block;
			width: 132px;
			margin: 0 auto 15px;
		}
		&__text {
			color: #7b7b7b;
			font-size: 1.1rem;
		}
	}
}