@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
body {
	background: $white;
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
}

.wrap {
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
	
	.show & {
		height: 100vh;
		overflow: hidden;
	}
	[class^="care care_"] &,
	[class^="relife relife_"] &,
	[class^="infine infine_"] &,
	[class^="richgreen richgreen_"] & {
		padding-bottom: 90px;
	}
}

.pc-view {
	display: none !important;
}

a {
	color: $linkColor;
}

.btn-fixed {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 20px;
	background: rgba(#000, 0.8);
	
	.btn--red {
		margin: 0;
		font-weight: bold;
		box-shadow: 0 2px 0 rgba(#000, 0.5);
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 25px;
	padding: 10px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xxxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 15px;
	bottom: 15px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba(#000, 0.6);
		color: $white;
		font-size: $xxxl;
		@include radius(4);
	}
	
	[class^="care care_"] &,
	[class^="relife relife_"] &,
	[class^="infine infine_"] &,
	[class^="richgreen richgreen_"] & {
		bottom: 105px;
	}
}

