@charset "utf-8";

$baseColor: #d70000;
$textColor: #333;
$linkColor: #006cb6;
$borderColor: #e8e8e8;
$gray: #ccc;
$light-gray: #f0f0f0;

$white: #fff;

$daily-act: #13979d;
$moisturizing: #847a0c;
$colorup: #b82b1d;
$xvolume: #8a5b39;
$silk-and-glow: #355691;
$becurly: #3e9f76;
$smooth: #af5d81;
$sunway: #9b0e10;
$flowerfall: #b6946f;

$rebuilding: #8a4671;
$no-dandruff: #509017;
$rebalancing: #4b7596;
$soothing: #a55446;
$hairloss: #c6802c;

$styling: #8b8b8b;
$richgreen: #5d7f52;

$life: #db822c;


@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin btn-lineup($product-color) {
	border: 1px solid $product-color;
	color: $product-color;
}

@mixin btn-lineup-hover($product-color) {
	background: $product-color;
	color: $white;
}


/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

